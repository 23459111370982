import axios from "axios";
import { URL_BACKEND_APP } from "../_const";

const API_CALLER = axios.create({
  baseURL: URL_BACKEND_APP + "/newsletter",
});

const newsletter_api = {
  create: (data, token) => {
    return API_CALLER.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
};

export default newsletter_api;
