import React, { useState } from "react";
import "./whoweare.scss";
import {
  FactoryOutlined,
  Timer,
  WineBarRounded,
  WorkHistory,
} from "@mui/icons-material";
import whatwedo_api from "../../_service/api.caller/whatWeDoCaller";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import {
  requestType,
  whatWedoItem,
  whatWedoTitle,
} from "../../types/globalTypes";

const WhoWeAre = () => {
  const [dataItem, setDataItem] = useState<whatWedoItem[]>([]);
  const [dataTitle, setDataTitle] = useState<whatWedoTitle[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const fetchItem = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    whatwedo_api.whatwedoItem
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataItem(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };
  const fetchTitle = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    whatwedo_api.whatwedoTitle
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataTitle(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  const getImage = () => {
    let img_url: string;
    if (!!dataTitle[0]?.path_img) {
      let array = dataTitle[0]?.path_img.split("/");
      let array_2: any = [];
      for (let i = 1; i < array.length; i++) {
        array_2.push(array[i]);
      }

      let url: string = array_2.join("/");
      img_url = `${URL_BACKEND_APP}/${url}`;
      return img_url;
    } else {
      img_url = "img/feature.jpg";
      return img_url;
    }
  };
  React.useEffect(() => {
    fetchItem();
    fetchTitle();
  }, []);
  return (
    <>
      {/* Features Start */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container ">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              {dataTitle[0]?.subtitle}
            </h5>
            <h1 className="mb-0">{dataTitle[0]?.title} </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <FactoryOutlined className="ico" />
                  </div>
                  <h4>{dataItem[0]?.title}</h4>
                  <p className="mb-0">{dataItem[0]?.content?.slice(0, 200)}</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <WineBarRounded className="ico" />
                  </div>
                  <h4>{dataItem[1]?.title}</h4>
                  <p className="mb-0">{dataItem[1]?.content?.slice(0, 200)}</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4  wow zoomIn"
              data-wow-delay="0.9s"
              style={{ minHeight: "350px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.1s"
                  src={getImage()}
                  style={{ objectFit: "cover" }}
                  alt="feature"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <WorkHistory className="ico" />
                  </div>
                  <h4>{dataItem[2]?.title}</h4>
                  <p className="mb-0">{dataItem[2]?.content?.slice(0, 200)}</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <Timer className="ico" />
                  </div>
                  <h4>{dataItem[3]?.title}</h4>
                  <p className="mb-0">{dataItem[3]?.content?.slice(0, 200)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Start */}
    </>
  );
};

export default WhoWeAre;
