import React from "react";
import "./successBar.scss";
import { CheckCircleRounded } from "@mui/icons-material";

export default function SuccessBar() {
  return (
    <div className="errorBar">
      <span className="sp1">{"Message sent"}</span>
      <CheckCircleRounded />
    </div>
  );
}
