import React from "react";
import "./activity.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import BreadCrump from "../../components/breadcrump/BreadCrump";
import { ActivitiesAll } from "../../components/activities/ActivitiesAll";
import Vision from "../../components/vision/Vision";
import Partener from "../../components/partener/Partener";

const Activity = () => {
  return (
    <>
      <Header />
      <BreadCrump title="Our activities" name="Activity" />
      <ActivitiesAll />
      <Vision />
      <Partener />
      <Footer />
    </>
  );
};

export default Activity;
