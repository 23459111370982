import { configureStore } from "@reduxjs/toolkit";
import darkModeSlice from "./darkModeSlice";


export const store = configureStore({
  reducer:{
    themeMode:darkModeSlice
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>