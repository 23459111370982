import React from "react";
import "./faq.scss";
import { ERROR_MESSAGE } from "../../_service/_const";
import { faqType, requestType } from "../../types/globalTypes";
import faq_api from "../../_service/api.caller/faqCaller";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const Faq = () => {
  const [data, setData] = React.useState<faqType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const fetchAll = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    faq_api
      .getAll()
      .then((response) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  React.useEffect(() => {
    fetchAll();
  }, []);

  let themeSelected = useSelector(
    (state: RootState) => state.themeMode.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }
  return (
    <>
      {/* Faq Section */}
      <div
        className={
          !darkMode
            ? "container-fluid faq-section bg-light py-5"
            : "container-fluid faq-section bg__dark py-5"
        }
      >
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div
              className="col-lg-6 col-xl-6 wow fadeInLeft"
              data-wow-delay="0.2s"
            >
              <div className="h-100">
                <div className="mb-5">
                  <h4 className="text-primary">Some Important FAQ's</h4>
                  <h3 className="display-4 mb-0">
                    Common Frequently Asked Questions
                  </h3>
                </div>
                <div className="accordion" id="accordionExample">
                  {data.map((value, index) => {
                    let idName: string = "";
                    let collapseName: string = "";
                    switch (index) {
                      case 0: {
                        idName = "headingOne";
                        collapseName = "collapseOne";
                        break;
                      }
                      case 1: {
                        idName = "headingTwo";
                        collapseName = "collapseTwo";
                        break;
                      }
                      case 2: {
                        idName = "headingThree";
                        collapseName = "collapseThree";
                        break;
                      }
                      case 3: {
                        idName = "headingFour";
                        collapseName = "collapseFour";
                        break;
                      }
                      case 4: {
                        idName = "headingFive";
                        collapseName = "collapseFive";
                        break;
                      }
                      case 5: {
                        idName = "headingSix";
                        collapseName = "collapseSix";
                        break;
                      }
                      case 6: {
                        idName = "headingSeven";
                        collapseName = "collapSeven";
                        break;
                      }
                      default:
                        break;
                    }
                    return (
                      <>
                        {!!value.faq_status && (
                          <div className="dark__accordion accordion-item ">
                            <h2 className="accordion-header" id={idName}>
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${collapseName}`}
                                aria-expanded="false"
                                aria-controls={collapseName}
                              >
                                Q: {value.question}?
                              </button>
                            </h2>
                            <div
                              id={collapseName}
                              className="accordion-collapse collapse"
                              aria-labelledby={idName}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {value.answer}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Q: What happens when we join Lire consulting?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Eius aperiam quae sunt quo iste veritatis tempore
                        ipsam perferendis natus quas? Deserunt amet officiis a
                        cum ipsa voluptatum harum. Magnam, natus.
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-xl-6 wow fadeInRight"
              data-wow-delay="0.4s"
            >
              <img src="img/faq.png" className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* /Faq Section */}
    </>
  );
};

export default Faq;
