import React from "react";
import "./team.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import BreadCrump from "../../components/breadcrump/BreadCrump";
import TeamMember from "../../components/team-member/TeamMember";
import Partener from "../../components/partener/Partener";
import Vision from "../../components/vision/Vision";

const Team = () => {
  return (
    <>
      <Header />
      <BreadCrump title="Meet our team" name="Team" />
      <TeamMember />
      <Vision />
      <Partener />
      <Footer />
    </>
  );
};

export default Team;
