import { ArrowForwardIosRounded } from "@mui/icons-material";
import "./breadcrump.scss";
import { Link, useLocation } from "react-router-dom";

const BreadCrump = ({ title, name }: { title: string; name: string }) => {
  const location = useLocation();
  let pathName = location.pathname;
  return (
    <div
      className="container-fluid bg-primary py-5 bg-header"
      style={{ marginBottom: "0px" }}
    >
      <div className="row py-5">
        <div
          className="col-12 pt-lg-5 mt-lg-5 text-center"
          style={{ fontFamily: "OrbitronRegular" }}
        >
          <h1
            className="display-4 text-white animated zoomIn"
            style={{
              fontFamily: "OrbitronRegular",
              fontSize: "35px",
              letterSpacing: "3px",
            }}
          >
            {title}
          </h1>
          <Link to="/" className="h5 text-white">
            Home
          </Link>
          <ArrowForwardIosRounded className="ico" />
          <Link to={pathName} className="h5 text-white">
            {name}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BreadCrump;
