import React from "react";
import "./testimony.scss";
import testimony_api from "../../_service/api.caller/testimonialCaller";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { requestType, testimonyType } from "../../types/globalTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { t } from "i18next";

const Testimony = () => {
  const [data, setData] = React.useState<testimonyType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const fetchAll = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    testimony_api
      .getAll()
      .then((response) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  React.useEffect(() => {
    fetchAll();
  }, []);

  let themeSelected = useSelector(
    (state: RootState) => state.themeMode.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }
  return (
    <>
      {/* Testimonial Start */}
      <div className="container-fluid py-5 wow " data-wow-delay="0.1s">
        <div className="container pb-2">
          <div
            className="section-title text-center position-relative pb-3 mb-4 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Testimonies</h5>
            <h1 className="mb-0">What Our Clients Say About Our Services</h1>
          </div>
          <div
            className="testimonial-carousel wow fadeInUp"
            data-wow-delay="0.6s"
          >
            {/* <div
            className="owl-carousel testimonial-carousel wow fadeInUp"
            data-wow-delay="0.6s"
          > */}

            {data.map((value, index) => {
              let imgURL: string;
              if (!!value.path_img) {
                let array = value.path_img.split("/");
                let array_2: any = [];
                for (let i = 1; i < array.length; i++) {
                  array_2.push(array[i]);
                }

                let url: string = array_2.join("/");
                imgURL = `${URL_BACKEND_APP}/${url}`;
              } else {
                imgURL = "img/testimonial-1.jpg";
              }

              return (
                <>
                  {!!value.testimony_status && (
                    <div
                      className={
                        darkMode
                          ? "dark__testimonial testimonial-item my-4"
                          : "testimonial-item bg-light my-4"
                      }
                      key={index}
                    >
                      <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img
                          className="img-fluid "
                          src={imgURL}
                          style={{
                            height: "60px",
                            width: "60px",
                            objectFit: "cover",
                            borderRadius: "50%",
                            backgroundColor: "#990",
                          }}
                          alt="testimony"
                        />
                        <div className="ps-4">
                          <h4 className="text-primary mb-1">{value.nom}</h4>
                          <small className="text-uppercase">
                            {value.profession}
                          </small>
                        </div>
                      </div>
                      <div className="pt-4 pb-5 px-5">{value.detail}</div>
                    </div>
                  )}
                </>
              );
            })}
            {/* <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="img/testimonial-1.jpg"
                  style={{ height: "60px", width: "60px" }}
                  alt="testimony"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">{"PPPPPPP"}</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="img/testimonial-1.jpg"
                  style={{ height: "60px", width: "60px" }}
                  alt="testimony"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Client Name</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="img/testimonial-1.jpg"
                  style={{ height: "60px", width: "60px" }}
                  alt="testimony"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">ClieOOOO</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="img/testimonial-1.jpg"
                  style={{ height: "60px", width: "60px" }}
                  alt="testimony"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Client Name</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Testimonial End */}
    </>
  );
};

export default Testimony;
