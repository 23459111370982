import "./styles/global.scss";
import "moment/locale/fr";

import {} from "@mui/material/Icon";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Service from "./pages/service/Service";
import Team from "./pages/team/Team";
import Custom from "./pages/custom/Custom";
import Request from "./pages/request/Request";
import NotFound from "./pages/404/NotFound";
import Activity from "./pages/activity/Activity";
import SingleActivity from "./pages/activity/SingleActivity";
import "./styles/dark.scss";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";

function App() {
  let themeSelected = useSelector(
    (state: RootState) => state.themeMode.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }
  const Layout = () => {
    return (
      <div className={darkMode ? "app dark" : ""}>
        <Outlet />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/contact", element: <Contact /> },
        { path: "/about", element: <About /> },
        { path: "/service", element: <Service /> },
        {
          path: "/activity",
          element: <Activity />,
        },
        { path: "/single-activity", element: <SingleActivity /> },
        { path: "/team", element: <Team /> },
        { path: "/custom", element: <Custom /> },
        // { path: "/request", element: <Request /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
