import React from "react";
import "./pagination.scss";
type Props = {
  activitiesPerPage: number;
  length: number;
  handlePagination: (pageNumber: number) => void;
  currentPage: number;
};
const Pagination = ({
  activitiesPerPage,
  length,
  handlePagination,
  currentPage,
}: Props) => {
  const paginationNumbers = [];
  for (let i = 1; i <= Math.ceil(length / activitiesPerPage); i++) {
    paginationNumbers.push(i);
  }

  return (
    <ul className="nav nav-pills pagination">
      {paginationNumbers.map((value, index) => (
        <li
          key={index}
          className={
            currentPage === value ? "text-primary bg-dark" : "text-primary"
          }
          onClick={() => handlePagination(value)}
        >
          Page {value}
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
