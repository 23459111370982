import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

// Configure the i18n instance
i18n
  .use(LanguageDetector) // Detect user language automatically
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
      //   es: { translation: translationES },
    },
    fallbackLng: "en", // Default language if detection fails
    debug: true, // Enable debug mode for development
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;
