import axios from "axios";
import { URL_BACKEND_APP } from "../_const";

const API_CALLER = axios.create({
  baseURL: URL_BACKEND_APP + "/lire",
});

const lireInfo_api = {
  getAll: () => {
    return API_CALLER.get("/read.php", {});
  },
  // create: (data, token) => {
  //   return API_CALLER.post("/create.php", data, {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   });
  // },
  // update: (id, data, token) => {
  //   return API_CALLER.put("/update.php?id=" + id, data, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  // },
  // getOne: (id, token) => {
  //   return API_CALLER.get(`/readById.php?id=${id}`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  // },
};

export default lireInfo_api;
