import React, { useState } from "react";
import "./caroussel.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Caroussel = () => {
  const { t } = useTranslation();

  return (
    <div
      id="header-carousel"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item">
          <img
            style={{ width: "100%", filter: "grayscale(100%)" }}
            src="img/carousel-2.jpg"
            alt="Image"
          />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center carousel_content">
            <div className="p-3" style={{ maxWidth: "900px" }}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                Creative & Innovative
                {/* {t("carousel.second.title")} */}
              </h5>
              <h1 className="text-white mb-md-4 animated zoomIn" style={{}}>
                Creative & Innovative Digital Solution
                {/* {t("carousel.second.subtitle")} */}
              </h1>
              <Link
                to="/service"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                Read more
                {/* {t("readMore")} */}
              </Link>
              <Link
                to="/contact"
                className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
              >
                {/* {t("contactUs")} */}
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="carousel-item active">
          <img className="w-100" src="img/carousel-1.jpg" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center carousel_content">
            <div className="p-3" style={{ maxWidth: "900px" }}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                Linking ideas to Result
                {/* {t("carousel.first.subtitle")} */}
              </h5>
              <h1 className=" text-white mb-md-4 animated ">
                We implement or execute programs or projects
                {/* {t("carousel.first.title")} */}
              </h1>
              <Link
                to="/activity"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                Read more
                {/* {t("readMore")} */}
              </Link>
              <Link
                to="/contact"
                className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
              >
                {/* {t("contactUs")} */}
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Caroussel;
