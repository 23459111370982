import React from "react";
import "./about.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import BreadCrump from "../../components/breadcrump/BreadCrump";
import AboutUs from "../../components/aboutus/AboutUs";
import WhoWeAre from "../../components/whoweare/WhoWeAre";
import Partener from "../../components/partener/Partener";
import Vision from "../../components/vision/Vision";
import BtnContainer from "../../components/btnContainer/BtnContainer";

const About = () => {
  return (
    <>
      <Header />
      <BreadCrump title="About us" name="About" />
      <AboutUs />
      <Vision />
      <WhoWeAre />
      <BtnContainer />
      <Partener />
      <Footer />
    </>
  );
};

export default About;
