import "./contact.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import BreadCrump from "../../components/breadcrump/BreadCrump";
import ContactList from "../../components/contact-list/index";
import Partener from "../../components/partener/Partener";

const Contact = () => {
  return (
    <>
      <Header />
      <BreadCrump title="Get in touch" name="Contact" />
      <ContactList />
      <Partener />
      <Footer />
    </>
  );
};

export default Contact;
