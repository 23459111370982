import axios from "axios";
import { URL_BACKEND_APP } from "../_const";

const API_CALLER = axios.create({
  baseURL: URL_BACKEND_APP + "/contact",
});

const contact_api = {
  create: (data) => {
    return API_CALLER.post("/create.php", data, {
      headers: {
        // Authorization: "Bearer " + token,
      },
    });
  },
};

export default contact_api;
