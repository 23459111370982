import "./aboutActivity.scss";
import { URL_BACKEND_APP } from "../../_service/_const";
import { activityType } from "../../types/globalTypes";
import { capitalize } from "lodash";

const AboutActivity = (props: { data: activityType }) => {
  let img_url: string;
  if (!!props.data.path_img) {
    let array = props.data.path_img.split("/");
    let array_2: any = [];
    for (let i = 1; i < array.length; i++) {
      array_2.push(array[i]);
    }

    let url: string = array_2.join("/");
    img_url = `${URL_BACKEND_APP}/${url}`;
  } else {
    img_url = "img/blog-1.jpg";
  }

  return (
    <div className="content">
      <img src={img_url} alt="Example" className="image-left imgActivity" />
      <div className="Container_about_activity">
        <div className=" pb-3 ">
          <h6 className="text-primary text-uppercase">
            Catégory: {capitalize(props.data.category)}
          </h6>
          <h3 className="mb-0">{capitalize(props.data.title)}</h3>
        </div>
        <div dangerouslySetInnerHTML={{ __html: props.data.content }} />
        {/* <div>{capitalize(props.data.content)}</div> */}
      </div>
    </div>
  );
};

export default AboutActivity;
