import React, { useState } from "react";
import "./partener.scss";
import { partenerType, requestType } from "../../types/globalTypes";
import partener_api from "../../_service/api.caller/partenerCaller";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import Carousel from "./Carousel";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Partener = () => {
  const [data, setData] = useState<partenerType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const fetchAll = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    partener_api
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };
  React.useEffect(() => {
    fetchAll();
  }, []);

  let themeSelected = useSelector(
    (state: RootState) => state.themeMode.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }

  let images: string[] = [];
  if (data.length > 0) {
    data.forEach((value) => {
      let img_url: string;
      if (!!value.path_logo) {
        let array = value.path_logo.split("/");
        let array_2: any = [];
        for (let i = 1; i < array.length; i++) {
          array_2.push(array[i]);
        }

        let url: string = array_2.join("/");
        img_url = `${URL_BACKEND_APP}/${url}`;
      } else {
        img_url = "img/vendor-6.jpg";
      }

      images.push(img_url);
    });
  }

  return (
    <div
      className="container-fluid py-5 wow fadeInUp px-5"
      data-wow-delay="0.1s"
    >
      <div className="container  mb-0">
        <div
          className="section-title text-center position-relative pb-3 mb-4 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5
            className={
              darkMode
                ? "fw-bold dark__text text-uppercase"
                : "fw-bold text-dark text-uppercase"
            }
          >
            {"Our partners"}
          </h5>
        </div>
        <div>
          <Carousel images={images} />
        </div>
      </div>
    </div>
  );
};

export default Partener;
