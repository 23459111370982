import "./activities.scss";
import { ArrowForward, CalendarMonth } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import actvity_api from "../../_service/api.caller/activityCaller";
import {
  activityTitleType,
  activityType,
  requestType,
} from "../../types/globalTypes";
import moment from "moment";
import { capitalize } from "lodash";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
// -----------------------------------------------
//HOME ACTIVITIES
// -----------------------------------------------
const Activities = () => {
  const [data, setData] = React.useState<activityType[]>([]);
  const [dataTitle, setDataTitle] = React.useState<activityTitleType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const fetchAll = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    actvity_api.activityItem
      .getAll()
      .then((response) => {
        console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };
  const fetchTitle = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    actvity_api.activityTitle
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataTitle(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };
  React.useEffect(() => {
    fetchAll();
    fetchTitle();
  }, []);

  let themeSelected = useSelector(
    (state: RootState) => state.themeMode?.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }
  return (
    <>
      {/* Blog Start */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              {dataTitle[0]?.subtitle}
            </h5>
            <h1 className="mb-0">{dataTitle[0]?.title}</h1>
          </div>
          <div className="row g-5">
            {data.splice(0, 3).map((value, index) => {
              let img_url: string;
              if (!!value.path_img) {
                let array = value.path_img.split("/");
                let array_2: any = [];
                for (let i = 1; i < array.length; i++) {
                  array_2.push(array[i]);
                }

                let url: string = array_2.join("/");
                img_url = `${URL_BACKEND_APP}/${url}`;
              } else {
                img_url = "img/blog-1.jpg";
              }

              return (
                <>
                  {!!value.activity_status && (
                    <div
                      className="col-lg-4 wow slideInUp"
                      data-wow-delay="0.3s"
                      key={index}
                    >
                      <div
                        className={
                          darkMode
                            ? "blog-item dark__activity rounded overflow-hidden"
                            : "blog-item bg-light rounded overflow-hidden"
                        }
                      >
                        <div className="blog-img position-relative overflow-hidden img_container">
                          <img
                            className="img-fluid img_Activity"
                            src={img_url}
                            alt=""
                          />
                          <a
                            className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                            href=""
                          >
                            {value?.category?.toUpperCase()}
                          </a>
                        </div>
                        <div className="p-4">
                          <div className="d-flex mb-2">
                            <small>
                              <CalendarMonth className="text-primary fs-5 mx-2" />
                              {moment(value?.activity_created_at).format("LL")}
                            </small>
                          </div>
                          <h6 className="mb-2">
                            {capitalize(value?.title?.slice(0, 25))}...
                          </h6>
                          {/* <p className="pr-4">
                            {capitalize(value.content).slice(0, 20)}...
                          </p> */}
                          <Link to="/single-activity" state={value}>
                            <a className="text-uppercase text-primary" href="#">
                              Read
                              <ArrowForward className="text-primary fs-5" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div className="g-5 mt-4 d-flex justify-content-end">
            <Link to={"/activity"}>
              Read Much More <ArrowForward className="text-primary fs-5" />
            </Link>
          </div>
        </div>
      </div>
      {/* Blog Start */}
    </>
  );
};

export default Activities;
