import "./aboutus.scss";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { AutoAwesome } from "@mui/icons-material";
import { Link } from "react-router-dom";
import about_api from "../../_service/api.caller/aboutCaller";
import React from "react";
import { aboutUsType, requestType } from "../../types/globalTypes";

const AboutUs = () => {
  const [dataTitle, setDataTitle] = React.useState<aboutUsType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const fetchTitle = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    about_api
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataTitle(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  const getImage = () => {
    let img_url: string;
    if (!!dataTitle[0]?.path_img) {
      let array = dataTitle[0]?.path_img.split("/");
      let array_2: any = [];
      for (let i = 1; i < array.length; i++) {
        array_2.push(array[i]);
      }

      let url: string = array_2.join("/");
      img_url = `${URL_BACKEND_APP}/${url}`;
      return img_url;
    } else {
      img_url = "img/about.jpg";
      return img_url;
    }
  };
  React.useEffect(() => {
    fetchTitle();
  }, []);
  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  {dataTitle[0]?.subtitle}
                </h5>
                <h1 className="mb-0">{dataTitle[0]?.title}</h1>
              </div>
              <p className="mb-4">{dataTitle[0]?.detail}</p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 d-flex align-center gap-2">
                    <AutoAwesome className="text-primary" />
                    Award Winning
                  </h5>
                  <h5 className="mb-3 d-flex align-center gap-2">
                    <AutoAwesome className="text-primary" />
                    Professional Staff
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3 d-flex align-center gap-2">
                    <AutoAwesome className="text-primary" />
                    24/7 Support
                  </h5>
                  <h5 className="mb-3 d-flex align-center gap-2">
                    <AutoAwesome className="text-primary" />
                    Fair Prices
                  </h5>
                </div>
              </div>
              <div
                className="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                {/* <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <PhoneCallback className="text-light" />
                </div> */}
                {/* <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">
                    {APP_CONST.PHONE_NUMBER}
                  </h4>
                </div> */}
              </div>
              <Link
                // to="/request"
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                Ask a question
              </Link>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={getImage()}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
