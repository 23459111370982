import "./header.scss";
import {
  DarkMode,
  Email,
  Facebook,
  Instagram,
  Language,
  LightModeOutlined,
  LinkedIn,
  Menu,
  Phone,
  Place,
  Twitter,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { ERROR_MESSAGE } from "../../_service/_const";
import { Link, useLocation } from "react-router-dom";
import { LOGO } from "../../assets";
import React from "react";
import lireInfo_api from "../../_service/api.caller/lireCaller";
import { lireInfoType, requestType } from "../../types/globalTypes";
import { triggerToggleMode } from "../../redux/darkModeSlice";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";

const Header = () => {
  let location = useLocation();
  let pathname = location.pathname;
  const [data, setData] = React.useState<lireInfoType[]>([]);
  const darkMode = useSelector((state: RootState) => state.themeMode.darkMode);

  const dispatch = useDispatch();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng); // Change the language dynamically
  };
  // changeLanguage(langeValue);

  const hangleTriggerTheme = () => {
    dispatch(triggerToggleMode());
  };

  const fetchAll = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    lireInfo_api
      .getAll()
      .then((response) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  React.useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <div>
        <div className="container-fluid bg_header px-5 d-none d-lg-block">
          <div className="row gx-0">
            <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
              <div
                className="d-inline-flex align-items-center"
                style={{ height: "45px" }}
              >
                <small className="me-3 text-light d-flex gap-2">
                  <Place className="ico" />
                  {data[0]?.adresse}
                </small>
                <small className="me-3 text-light d-flex gap-2">
                  <Phone className="ico" />
                  {data[0]?.telephone}
                </small>
                <small className="text-light d-flex gap-2">
                  <Email className="ico" />
                  {data[0]?.email}
                </small>
              </div>
            </div>

            <div className="col-lg-4 text-center text-lg-end">
              <div
                className="d-inline-flex align-items-center"
                style={{ height: "45px" }}
              >
                {!!data[0]?.twitter && (
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href={"http://" + data[0]?.twitter}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    target="_blank"
                  >
                    <Twitter className="ico" />
                  </a>
                )}

                {!!data[0]?.facebook && (
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href={"http://" + data[0]?.facebook}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    target="_blank"
                  >
                    <Facebook className="ico" />
                  </a>
                )}

                {!!data[0]?.linkden && (
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href={"http://" + data[0]?.linkden}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    target="_blank"
                  >
                    <LinkedIn className="ico" />
                  </a>
                )}

                {!!data[0]?.instagram && (
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href={"http://" + data[0]?.instagram}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    target="_blank"
                  >
                    <Instagram className="ico" />
                  </a>
                )}

                {/* ----------------------dropdown language--------------- */}
                <div className="dropDown_container ms-3">
                  <Language className="ico" />
                  <div className="selectContainet text-white">
                    <span
                      onClick={() => {
                        changeLanguage("en");
                      }}
                      // className={langeValue === "en" ? "text-primary" : ""}
                    >
                      EN
                    </span>
                    |
                    <span
                      onClick={() => {
                        changeLanguage("fr");
                      }}
                      // className={langeValue === "fr" ? "text-primary" : ""}
                    >
                      FR
                    </span>
                    {/* <select
                      onChange={(e) => {
                        setlangeValue(e.target.value);
                        changeLanguage(langeValue);
                      }}
                      value={langeValue}
                    >
                      <option value={"fr"}>Français</option>
                      <option value={"en"}>English</option>
                    </select> */}
                  </div>
                </div>
                <div className="darkMode_btn ms-3" onClick={hangleTriggerTheme}>
                  <LightModeOutlined
                    className={!darkMode ? "text-primary ico" : "ico"}
                  />

                  <DarkMode className={darkMode ? "text-primary ico" : "ico"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0 shadown-sm">
          <Link to="/" className="navbar-brand p-0">
            <h1
              className="m-0"
              style={{ fontFamily: "OrbitronMedium", fontSize: "25px" }}
            >
              <img src={LOGO} className="logo" />
              {data[0]?.lire_designation}
            </h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <Menu />
          </button>
          <div className="collapse navbar-collapse gap-4" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link
                to="/"
                className={
                  pathname === "/"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                {/* {t("home")} */}
                Home
              </Link>
              <Link
                to="/about"
                className={
                  pathname === "/about"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                {/* {t("about")} */}
                About
              </Link>
              <Link
                to="/service"
                className={
                  pathname === "/service"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                {/* {t("services")} */}
                Services
              </Link>
              <Link
                to="/activity"
                className={
                  pathname === "/activity"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                {/* {t("activity")} */}
                Activities
              </Link>

              {/* <Link
                to="/custom"
                className={
                  pathname === "/custom"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Customers
              </Link> */}
              <Link
                to="/team"
                className={
                  pathname === "/team"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                {/* {t("ourTeam")} */}
                Our team
              </Link>
              <div
                className="darkMode_btn_sm ms-3"
                onClick={hangleTriggerTheme}
              >
                <LightModeOutlined
                  className={!darkMode ? "text-primary ico" : "ico"}
                />

                <DarkMode className={darkMode ? "text-primary ico" : "ico"} />
              </div>
            </div>

            <Link to="/contact" className="btn btn-primary py-2 px-4 ms-3">
              Contact us
              {/* {t("contactUs")} */}
            </Link>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
