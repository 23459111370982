import React from "react";
import "./custom.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import BreadCrump from "../../components/breadcrump/BreadCrump";

const Custom = () => {
  return (
    <>
      <Header />
      <BreadCrump title="Our customers" name="Custom" />
      <Footer />
    </>
  );
};

export default Custom;
