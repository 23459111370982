import React, { useEffect, useState } from "react";
import "./carousel.css";
import { ArrowLeft, ArrowLeftRounded } from "@mui/icons-material";

const Carousel = ({ images }: { images: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = images.length;

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < totalImages - 2 ? prevIndex + 1 : 0
    );
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : totalImages - 3
    );
  };
  // Automatically go to next set of images every 3 seconds
  useEffect(() => {
    const interval = setInterval(goToNext, 3000); // 3000ms = 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentIndex]); // Re-run effect when currentIndex changes

  return (
    <div className="carousel">
      <button
        className="carousel-button prev bg-transparent"
        onClick={goToPrevious}
      >
        {"<"}
      </button>
      <div
        className="carousel-content"
        style={{ transform: `translateX(-${currentIndex * 33.33}%)` }}
      >
        {images.map((image, index) => (
          <div key={index} className="carousel-image">
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
      <button
        className="carousel-button next bg-transparent"
        onClick={goToNext}
      >
        {">"}
      </button>
    </div>
  );
};

export default Carousel;
