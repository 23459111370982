import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import BreadCrump from "../../components/breadcrump/BreadCrump";
import Partener from "../../components/partener/Partener";
import Vision from "../../components/vision/Vision";
import AboutActivityBottonSection from "../../components/activities/AboutActivityBottonSection";
import AboutActivity from "../../components/activities/AboutActivity";
import { useLocation } from "react-router-dom";

const SingleActivity = () => {
  const location = useLocation();
  const data = location.state;
  return (
    <>
      <Header />
      <BreadCrump title="Activity" name="About" />

      <AboutActivity data={data} />
      <AboutActivityBottonSection />

      <Vision />
      <Partener />
      <Footer />
    </>
  );
};

export default SingleActivity;
