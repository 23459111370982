import React from "react";
import "./btncontainer.scss";
import { Link } from "react-router-dom";
import { t } from "i18next";

const BtnContainer = () => {
  return (
    <>
      <section
        id="call-to-action"
        className="call-to-action section dark-background"
      >
        <img src="img/carousel-2.jpg" alt="bg_image" />

        <div className="container">
          <div
            className="row justify-content-center"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="col-xl-10">
              <div className="text-center">
                <h3 className="text-light">
                  Our Company Missions
                  {/* {t("mission.label")} */}
                </h3>
                <p className="text-light">
                  {/* {t("mission.content")} */}
                  We are on a mission to help organizations set up projects,
                  conduct evaluations, carry out walking studies, design project
                  management tools, create and manage databases, develop
                  indicator monitoring plans, train the public on monitoring and
                  evaluation systems and produce analytical and thematic maps.
                  We also consult and advise managers and leaders of NGOs and
                  agencies to improve and effectively execute accountable,
                  equitable and sustainable programs
                </p>
                <Link
                  className="btn btn-primary border-primary border-3 border-color-primary py-md-3 px-md-5 animated slideInRight"
                  to="/contact"
                >
                  Join us
                  {/* {t("joinUs")} */}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BtnContainer;
