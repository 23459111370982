import React from "react";
import "./service.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import BreadCrump from "../../components/breadcrump/BreadCrump";
import ServiceList from "../../components/service-list/ServiceList";
import Testimony from "../../components/testimony/Testimony";
import Partener from "../../components/partener/Partener";
import BtnContainer from "../../components/btnContainer/BtnContainer";

const Service = () => {
  return (
    <>
      <Header />
      <BreadCrump title="Our offers" name="Services" />
      <ServiceList />
      <BtnContainer />
      <Testimony />
      <Partener />
      <Footer />
    </>
  );
};

export default Service;
