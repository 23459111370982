import {PayloadAction, createSlice } from "@reduxjs/toolkit";

interface darkModeInitialState{
  darkMode: boolean
}

const initialState:darkModeInitialState = {
  darkMode: false
};
const darkModeSlice = createSlice({
  name: "darkModeTheme",
  initialState,
  reducers: {
    triggerLightMode: (state, action:PayloadAction<boolean>) => {
      state.darkMode=false
    },
    triggerDarkMode: (state, action:PayloadAction<boolean>) => {
      state.darkMode=true
    },
    triggerToggleMode: (state) => {
      state.darkMode=!state.darkMode;
      let theme:string;
      (state.darkMode?theme="darkMode":theme="lightMode")
      localStorage.setItem("themeMode", theme)
    },
  },
});

export const {
  triggerDarkMode,
  triggerLightMode,triggerToggleMode
} = darkModeSlice.actions;

export default darkModeSlice.reducer;
