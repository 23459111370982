import "./home.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import Caroussel from "../../components/caroussel/Caroussel";
import WhoWeAre from "../../components/whoweare/WhoWeAre";
import Activities from "../../components/activities/Activities";
import Faq from "../../components/faq/Faq";
import Partener from "../../components/partener/Partener";
import BtnContainer from "../../components/btnContainer/BtnContainer";
import Testimony from "../../components/testimony/Testimony";

const Home = () => {
  return (
    <>
      <Header />
      <Caroussel />
      <WhoWeAre />
      <Activities />
      <BtnContainer />
      <Faq />
      <Testimony />
      <Partener />
      <Footer />
    </>
  );
};

export default Home;
