import React, { useState } from "react";
import "./contactLit.scss";
import { Mail, Phone, Place } from "@mui/icons-material";
import { lireInfoType, requestType } from "../../types/globalTypes";
import { ERROR_MESSAGE } from "../../_service/_const";
import lireInfo_api from "../../_service/api.caller/lireCaller";
import contact_api from "../../_service/api.caller/contactCaller";
import MyLoadingv3 from "../loader/Loader_v3";
import ErrorBar from "../error/ErrorBar";
import SuccessBar from "../er_success/SuccessBar";

type entriesDataType = {
  nom: string;
  email: string;
  subject: string;
  message: string;
};
const ContactList = () => {
  const [data, setData] = useState<lireInfoType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
    succussMessage: null,
  });

  const [userEntries, setUserEntries] = useState<entriesDataType>({
    nom: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e: any) => {
    setUserEntries({ ...userEntries, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (
      userEntries.nom.toString().length < 1 ||
      userEntries.email.toString().length < 1 ||
      userEntries.subject.toString().length < 1 ||
      userEntries.message.toString().length < 1
    ) {
      return setState({
        isLoading: false,
        errorMessage: "Complete all the fields",
      });
    }

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: null,
    });

    let { nom, email, subject, message } = userEntries;

    contact_api
      .create({
        name: nom,
        email,
        subject,
        message,
      })
      .then((response) => {
        setState((prev) => ({
          errorMessage: null,
          isLoading: false,
          succussMessage: "sent",
        }));

        // after three second set success message to false
        setTimeout(() => {
          setState((prev) => ({
            errorMessage: null,
            isLoading: false,
            succussMessage: null,
          }));
        }, 3000);

        setUserEntries({
          nom: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((error: any) => {
        setUserEntries({
          nom: "",
          email: "",
          subject: "",
          message: "",
        });
        if (error.response.data && error.response.data.status === 401) {
          return setState({
            isLoading: false,
            errorMessage: "Error on sending, try again later",
          });
        }
        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: "Connexion Error,  try again later",
          });
        }
        setState({
          isLoading: false,
          errorMessage: "Error on loading, try again later",
        });
      });
  };

  const fetchAll = () => {
    lireInfo_api
      .getAll()
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error: any) => {
        // console.log(error);
      });
  };
  React.useEffect(() => {
    fetchAll();
  }, []);
  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
            <h1 className="mb-0">
              If You Have Any Query, Feel Free To Contact Us
            </h1>
          </div>
          <div className="row g-5 mb-5">
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.1s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <Phone className="text-light" />{" "}
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">{data[0]?.telephone}</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.4s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <Mail className="text-light" />{" "}
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Email to get information</h5>
                  <h4 className="text-primary mb-0">{data[0]?.email}</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.8s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <Place className="text-light" />{" "}
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Visit our office</h5>
                  <h4 className="text-primary mb-0">{data[0]?.adresse}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
              {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
              {!!state.succussMessage && <SuccessBar />}
              <MyLoadingv3 isLoading={state.isLoading} />
              <form>
                <div className="row g-3 mt-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Name"
                      style={{ height: "55px" }}
                      name="nom"
                      value={userEntries.nom}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Email"
                      style={{ height: "55px" }}
                      name="email"
                      value={userEntries.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Subject"
                      style={{ height: "55px" }}
                      name="subject"
                      value={userEntries.subject}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control border-0 bg-light px-4 py-3"
                      rows={4}
                      placeholder="Message"
                      name="message"
                      value={userEntries.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
              <iframe
                className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d584356.733706584!2d29.234306417765033!3d-1.6761965210756744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2scd!4v1724964048492!5m2!1sfr!2scd"
                style={{ minHeight: "350px", border: 0 }}
                aria-hidden="false"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactList;
